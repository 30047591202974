/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import arClient from '../../util/api_client';
// import { fa } from './static/fetchAll';
import { HypeAuditorFilters } from './static/hypeAuditorFilters';
import { ModashFilters } from './static/hypeAuditorFilters';
import { formatCreatorDataListing, formatCreatorDataListingV3 } from './Utils/creatorsLib';
import { formatContentData } from './Utils/formatContentData';
import getTotalPostCommentsCount from './Utils/getTotalPostCommentsCount';
import reconcileDemographicsData from './Utils/reconcileDemographicsData';
import reconcileCreatorFiltersData from './Utils/reconcileCreatorFiltersData';
import { ENV } from '../../constants/apiUrls';
import dummyContentAnalysisData from './contentAnalysisImage.json';
import dummyVideoData from './contentAnalysisVideo.json';
import dummyCreatorContentData from './dummyInfContent.json';
import { formatContentAnalysisData } from './CreatorDetailsPage/formatContentAnalysisData';

const initialState = {
  personaList: [],
  personaListLoading: false,

  personaCreationSelectedCampaigns: '',
  personaCreationAvaliableInterests: [],
  personaCreationAvaliableInterestsLoading: false,
  personaCreationSuggestedInterests: [],
  personaCreationSuggestedInterestsCanceled: false,
  personaCreationSelectedInterests: [],
  personaHashtagKeywordsCanceled: false,
  personaHashtagKeywords: [],
  personaSEOKeywordsCanceled: false,
  personaSEOKeywords: [],

  personaCreationFiltersActiveNetwork: 'instagram',
  personaCreationFiltersisLoading: true,
  personaCreationFiltersValidationErrors: {
    similarAsError: null,
    advancedFieldsError: null,
  },
  personaCreationFilters: {
    instagram: {
      selectedForSubmit: false,
      isSaving: false,
      isUntouched: true,
      invalidFields: [],
      selectedFilters: {
        social_network: 'instagram',
        influencer_followers: { min: 100000, max: 300000 },
        // account_geo: {
        //   country: ['us'],
        // },
        audience_location: [
          {
            id: 148838,
            label: 'United States',
            weight: 0.5,
          },
        ],
      },
      noMaxLimit: false,
      LDACompliance: null,
      filters: {
        // income: {
        //   id: '50k',
        //   prc: 30,
        // },
        social_network: 'instagram',
        // ...HypeAuditorFilters,
        ...ModashFilters,
      },
    },
    tiktok: {
      selectedForSubmit: false,
      isSaving: false,
      isUntouched: true,
      invalidFields: [],
      selectedFilters: {
        social_network: 'tiktok',
        influencer_followers: { min: 100000, max: 300000 },
        // account_geo: {
        //   country: ['us'],
        // },
        audience_location: [
          {
            id: 148838,
            label: 'United States',
            weight: 0.5,
          },
        ],
      },
      noMaxLimit: false,
      LDACompliance: null,
      filters: {
        social_network: 'tiktok',
        // views_avg: {
        //   from: 5000,
        //   to: 50000,
        // },
        // comments_avg: { from: 10 },
        // alikes_avg: { from: 10 },
        // likes_growth_prc: {
        //   period: '90d',
        //   from: 20,
        // },
        // likes_count: {
        //   from: 5000,
        //   to: 50000,
        // },
        // ...HypeAuditorFilters,
        ...ModashFilters,
      },
    },
    youtube: {
      selectedForSubmit: false,
      isSaving: false,
      isUntouched: true,
      invalidFields: [],
      selectedFilters: {
        social_network: 'youtube',
        influencer_followers: { min: 100000, max: 300000 },
        // account_geo: {
        //   country: ['us'],
        // },
        audience_location: [
          {
            id: 148838,
            label: 'United States',
            weight: 0.5,
          },
        ],
      },
      noMaxLimit: false,
      LDACompliance: null,
      filters: {
        social_network: 'youtube',
        // views_avg: { from: 5000, to: 50000 },
        // comments_avg: { from: 10 },
        // alikes_avg: { from: 10 },
        // reactions_rate: ['any'],
        // ...HypeAuditorFilters,
        ...ModashFilters,
      },
    },
    twitter: {
      selectedForSubmit: false,
      isSaving: false,
      isUntouched: true,
      invalidFields: [],
      selectedFilters: {
        social_network: 'twitter',
        subscribers_count: { from: 100000, to: 300000 },
      },
      LDACompliance: null,
      noMaxLimit: false,
      filters: {
        social_network: 'twitter',
        ...HypeAuditorFilters,
      },
    },
    twitch: {
      selectedForSubmit: false,
      isSaving: false,
      isUntouched: true,
      invalidFields: [],
      selectedFilters: {
        social_network: 'twitch',
        subscribers_count: { from: 100000, to: 300000 },
      },
      noMaxLimit: false,
      LDACompliance: null,
      filters: {
        social_network: 'twitch',
        ...HypeAuditorFilters,
      },
    },
  },

  personaResultsList: [],
  personaResultsListLoading: false,

  PersonaCreateErrorMsg: '',
  PersonaCreateSuccess: false,
  PersonaCreateBuilderRequestDataId: null,

  PersonaStatusUpdateLoading: false,

  CreatorFiltersSavedSuccess: false,
  CreatorFiltersSavedErrMsg: false,

  details: {},
  detailsLoading: true,
  postContentByIds: {},
  postContentIsLoading: false,

  influencerList: [],
  influencerERList: [],
  isInfluencerListLoading: true,
  isInfluencerERListLoading: true,
  influencerDetails: {},
  influencerDetailsLoading: true,

  influencerNotes: [],
  influencerNotesLoading: false,

  influencersBrandSaftey: [],

  chatAnswers: [],

  isCompareActive: false,
  comparePersonaCampaign: '',
  selectedPersonaForCompArr: [],

  influencerContent: [],
  isInfluencerContentLoading: false,

  audienceList: [],
  isAudienceListLoading: false,

  isInfluencerRevisionsFound: false,
  isInfluencerRevisionsFoundLoading: false,

  influencerRevisionsList: [],
  isInfluencerRevisionsListLoading: false,

  influencerRevisionsConversation: {},
  isInfluencerRevisionsConversationLoading: false,
  contentAgreementData: null,
  selectedContentRevisionId: '',

  influencerMatchingContent: [],
  influencerMatchingContentLoading: false,

  personasLiteList: [],
  personasLiteListLoading: false,
};

const PersonasSlice = createSlice({
  name: 'Personas',
  initialState,
  reducers: {
    setPostContentByIds(state, action) {
      const { payload } = action;
      state.postContentByIds = payload;
    },

    setPostContentIsLoading(state, action) {
      const { payload } = action;
      state.postContentIsLoading = payload;
    },

    resetPersonaList(state) {
      state.personaList = initialState.personaList;
    },

    setPersonaList(state, action) {
      const { payload } = action;
      state.personaList = payload;
    },

    setPersonaListLoading(state, action) {
      const { payload } = action;
      state.personaListLoading = payload;
    },

    setPersonaCreationSelectedCampaigns(state, action) {
      const { payload } = action;
      state.personaCreationSelectedCampaigns = payload;
    },

    setPersonaCreationAvaliableInterests(state, action) {
      const { payload } = action;
      state.personaCreationAvaliableInterests = payload;
    },

    setPersonaCreationAvaliableInterestsLoading(state, action) {
      const { payload } = action;
      state.personaCreationAvaliableInterestsLoading = payload;
    },

    setPersonaCreationSuggestedInterests(state, action) {
      const { payload } = action;
      state.personaCreationSuggestedInterests = payload;
    },

    setPersonaCreationSuggestedInterestsCanceled(state, action) {
      const { payload } = action;
      state.personaCreationSuggestedInterestsCanceled = payload;
    },

    setPersonaCreationSelectedInterests(state, action) {
      const {
        payload: { selectedInterests },
      } = action;
      state.personaCreationSelectedInterests = selectedInterests;
    },

    setPersonaHashtagKeywords(state, action) {
      const { payload } = action;
      state.personaHashtagKeywords = payload;
    },

    setPersonaHashtagKeywordsCanceled(state, action) {
      const { payload } = action;
      state.personaHashtagKeywordsCanceled = payload;
    },

    setPersonaSEOKeywords(state, action) {
      const { payload } = action;
      state.personaSEOKeywords = payload;
    },

    setPersonaSEOKeywordsCanceled(state, action) {
      const { payload } = action;
      state.personaSEOKeywordsCanceled = payload;
    },

    // Filters
    setPersonaCreationFiltersActiveNetwork(state, action) {
      const { payload } = action;
      state.personaCreationFiltersActiveNetwork = payload;
    },

    setFiltersLoadingState(state, action) {
      const { payload } = action;
      state.personaCreationFiltersisLoading = payload;
    },

    setFiltersFromSaved(state, action) {
      const { payload } = action;
      state.personaCreationFilters = payload;
    },

    resetFiltersForNetwork(state, action) {
      const { payload } = action;
      state.personaCreationFilters[payload] =
        initialState.personaCreationFilters[payload];
    },

    setFilterValue(state, action) {
      const { payload } = action;

      state.personaCreationFilters[payload.network].filters[payload.filter] =
        payload.value;

      state.personaCreationFilters[payload.network].noMaxLimit =
        payload.noMaxLimit;

      state.personaCreationFilters[payload.network].selectedFilters[
        payload.filter
      ] = payload.value;
    },
    setFilterSysVal(state, action) {
      const { payload } = action;
      state.personaCreationFilters[payload.network][payload.filter] =
        payload.value;
    },
    setFiltersErrorsVal(state, action) {
      const { payload } = action;
      state.personaCreationFiltersValidationErrors[payload.type] =
        payload.errorText;
    },
    // Filters end

    resetResultsList(state) {
      state.personaResultsList = initialState.personaResultsList;
    },

    setResultsList(state, action) {
      const { payload } = action;
      state.personaResultsList = payload;
    },

    setResultsListLoading(state, action) {
      const { payload } = action;
      state.personaResultsListLoading = payload;
    },

    setPersonaCreateErrorMsg(state, action) {
      const { payload } = action;
      state.PersonaCreateErrorMsg = payload;
    },

    setPersonaCreateSuccess(state, action) {
      const { payload } = action;
      state.PersonaCreateSuccess = payload;
    },

    setPersonaCreateBuilderRequestDataId(state, action) {
      const { payload } = action;
      state.PersonaCreateBuilderRequestDataId = payload;
    },

    setCreatorFiltersSavedSuccess(state, action) {
      const { payload } = action;
      state.CreatorFiltersSavedSuccess = payload;
    },

    setCreatorFiltersSavedErrMsg(sate, action) {
      const { payload } = action;
      state.CreatorFiltersSavedErrMsg = payload;
    },

    // PERSONA DETAILS
    resetPersonaDetails(state) {
      state.details = initialState.details;
    },

    updatePersonaDetails(state, action) {
      const { payload } = action;
      state.details = payload;
    },

    setPersonaDetailsName(state, action) {
      const { payload } = action;
      state.details.name = payload;
    },

    setPersonaDetailsSentiment(state, action) {
      const { payload } = action;
      state.details.sentiment = payload;
    },

    setPersonaDetailsCommunity(state, action) {
      const { payload } = action;
      state.details.community = payload;
    },

    setPersonaDetailsFollowers(state, action) {
      const { payload } = action;
      state.details.followers = payload;
    },

    setPersonaDetailsCommentsNPosts(state, action) {
      const { payload } = action;
      state.details.postsComments = payload;
    },

    setPersonaDetailsSummary(state, action) {
      const { payload } = action;
      state.details.summary = payload;
    },

    setPersonaDetailsPrimaryInt(state, action) {
      const { payload } = action;
      state.details.primaryInterests = payload;
    },

    setPersonaDetailsSecondraryInt(state, action) {
      const { payload } = action;
      state.details.secondraryInterests = payload;
    },

    setPersonaDetailsTopicsTableData(state, action) {
      const { payload } = action;
      state.details.topicsTableData = payload;
    },

    setPersonaDetailsIncomeData(state, action) {
      const { payload } = action;
      state.details.incomeData = payload;
    },

    setPersonaDetailsAgeRangesData(state, action) {
      const { payload } = action;
      state.details.ageRangesData = payload;
    },

    setPersonaDetailsEthnicityData(state, action) {
      const { payload } = action;
      state.details.ethnicityData = payload;
    },

    setPersonaDetailsMapData(state, action) {
      const { payload } = action;
      state.details.mapData = payload;
    },

    setPersonaDetailsEduAndMartialData(state, action) {
      const { payload } = action;
      state.details.eduAndMartialData = payload;
    },

    setPersonaDetailsIsLoading(state, action) {
      const { payload } = action;
      state.detailsLoading = payload;
    },

    setPersonaStatusUpdateLoading(state, action) {
      const { payload } = action;
      state.PersonaStatusUpdateLoading = payload;
    },

    // Influencer listening part
    setInfluencerListContent(state, action) {
      const { payload } = action;
      state.influencerList = payload;
    },
    setInfluencerER(state, action) {
      const { payload } = action;
      state.influencerERList = payload;
    },
    setInfluencerLoadingState(state, action) {
      const { payload } = action;
      state.isInfluencerListLoading = payload;
    },
    setInfluencerERLoadingState(state, action) {
      const { payload } = action;
      state.isInfluencerERListLoading = payload;
    },
    setInfluencerNotesContent(state, action) {
      const { payload } = action;
      state.influencerNotes = payload;
    },
    setInfluencerNotesLoadingState(state, action) {
      const { payload } = action;
      state.influencerNotesLoading = payload;
    },

    setInfluencerDetailsContent(state, action) {
      const { payload } = action;
      state.influencerDetails = payload;
    },
    setInfluencerDetailsLoadingState(state, action) {
      const { payload } = action;
      state.influencerDetailsLoading = payload;
    },
    setInfluencerDetailsInsight(state, action) {
      const { payload } = action;
      state.influencerDetails.all.insights = payload;
    },
    setInfluencerDetailsProsCons(state, action) {
      const { payload } = action;
      state.influencerDetails.all.prosCons = payload;
    },
    setChatGPTAnswers(state, action) {
      const { payload } = action;
      state.chatAnswers = [...state.chatAnswers, payload];
    },
    setIsCompareActive(state, action) {
      const { payload } = action;
      state.isCompareActive = payload;
    },
    setComparePersonaCampaign(state, action) {
      const { payload } = action;
      state.comparePersonaCampaign = payload;
    },
    setSelectedPersonaForCompArr(state, action) {
      const { payload } = action;
      state.selectedPersonaForCompArr = payload;
    },
    resetChatGPTAnswers(state, action) {
      state.chatAnswers = initialState.chatAnswers;
    },
    setInfluencerContent(state, action) {
      const { payload } = action;
      state.influencerContent = payload;
    },
    setInfluencerContentLoadingState(state, action) {
      const { payload } = action;
      state.isInfluencerContentLoading = payload;
    },
    setAudienceList(state, action) {
      const { payload } = action;
      state.audienceList = payload;
    },
    setAudienceListLoadingState(state, action) {
      const { payload } = action;
      state.isAudienceListLoading = payload;
    },
    setIsInfluencerRevisionsFound(state, action) {
      const { payload } = action;
      state.isInfluencerRevisionsFound = payload;
    },
    setIsInfluencerRevisionsFoundLoading(state, action) {
      const { payload } = action;
      state.isInfluencerRevisionsFoundLoading = payload;
    },
    setInfluencerRevisionsList(state, action) {
      const { payload } = action;
      state.influencerRevisionsList = payload;
    },
    setIsInfluencerRevisionsListLoading(state, action) {
      const { payload } = action;
      state.isInfluencerRevisionsListLoading = payload;
    },
    setInfluencerRevisionsConversation(state, action) {
      const { payload } = action;
      state.influencerRevisionsConversation = payload;
    },
    setIsInfluencerRevisionsConversationLoading(state, action) {
      const { payload } = action;
      state.isInfluencerRevisionsConversationLoading = payload;
    },
    setContentAgreementData(state, action) {
      const { payload } = action;
      state.contentAgreementData = payload;
    },
    setSelectedContentRevisionId(state, action) {
      const { payload } = action;
      state.selectedContentRevisionId = payload;
    },
    setInfluencersBrandSafteyData(state, action) {
      const { payload } = action;
      state.influencersBrandSaftey = payload;
    },
    setInfluencerMatchingContent(state, action) {
      const { payload } = action;
      state.influencerMatchingContent = payload;
    },
    setInfluencerMatchingContentLoading(state, action) {
      const { payload } = action;
      state.influencerMatchingContentLoading = payload;
    },
    setPersonaLiteList(state, action) {
      const { payload } = action;
      state.personasLiteList = payload;
    },
    setPersonaLiteListLoading(state, action) {
      const { payload } = action;
      state.personasLiteListLoading = payload;
    },
  },
});

export const {
  resetPersonaList,
  setPersonaList,
  setPersonaListLoading,
  setPersonaCreationSelectedCampaigns,
  setPersonaCreationAvaliableInterests,
  setPersonaCreationAvaliableInterestsLoading,
  setPersonaCreationSuggestedInterests,
  setPersonaCreationSuggestedInterestsCanceled,
  setPersonaCreationSelectedInterests,
  setPersonaCreationFiltersActiveNetwork,
  setFiltersLoadingState,
  setFiltersFromSaved,
  resetFiltersForNetwork,
  setFilterValue,
  setFilterSysVal,
  setFiltersErrorsVal,
  resetResultsList,
  setResultsList,
  setResultsListLoading,
  setPersonaCreateErrorMsg,
  setPersonaCreateSuccess,
  setPersonaCreateBuilderRequestDataId,
  setCreatorFiltersSavedErrMsg,
  setCreatorFiltersSavedSuccess,
  resetPersonaDetails,
  setPersonaDetailsName,
  setPersonaDetailsSentiment,
  setPersonaDetailsCommunity,
  setPersonaDetailsFollowers,
  setPersonaDetailsCommentsNPosts,
  setPersonaDetailsSummary,
  setPersonaDetailsPrimaryInt,
  setPersonaDetailsSecondraryInt,
  setPersonaDetailsTopicsTableData,
  setPersonaDetailsIncomeData,
  setPersonaDetailsAgeRangesData,
  setPersonaDetailsEthnicityData,
  setPersonaDetailsMapData,
  setPersonaDetailsEduAndMartialData,
  setPersonaDetailsIsLoading,
  setPersonaStatusUpdateLoading,
  updatePersonaDetails,
  setPostContentIsLoading,
  setPostContentByIds,
  setInfluencerListContent,
  setInfluencerLoadingState,
  setInfluencerNotesContent,
  setInfluencerNotesLoadingState,
  setInfluencerDetailsContent,
  setInfluencerDetailsLoadingState,
  setInfluencerDetailsInsight,
  setInfluencerDetailsProsCons,
  setChatGPTAnswers,
  resetChatGPTAnswers,
  setIsCompareActive,
  setComparePersonaCampaign,
  setSelectedPersonaForCompArr,
  setInfluencerER,
  setInfluencerERLoadingState,
  setInfluencerContent,
  setInfluencerContentLoadingState,
  setPersonaHashtagKeywordsCanceled,
  setPersonaHashtagKeywords,
  setPersonaSEOKeywordsCanceled,
  setPersonaSEOKeywords,
  setAudienceList,
  setAudienceListLoadingState,
  setIsInfluencerRevisionsFound,
  setIsInfluencerRevisionsFoundLoading,
  setInfluencerRevisionsList,
  setIsInfluencerRevisionsListLoading,
  setInfluencerRevisionsConversation,
  setIsInfluencerRevisionsConversationLoading,
  setContentAgreementData,
  setSelectedContentRevisionId,
  setInfluencersBrandSafteyData,
  setInfluencerMatchingContent,
  setInfluencerMatchingContentLoading,
  setPersonaLiteList,
  setPersonaLiteListLoading,
} = PersonasSlice.actions;

export const resetAllPersonas = () => async dispatch => {
  dispatch(resetPersonaList());
};


export const getPersonasList = (
  listOnly = false,
  bId = false,
  indexId = false
) => async dispatch => {


  const callback = resp => {
    if (ENV !== 'PRODUCTION') console.log(resp);
    // dispatch(setPersonaDetailsIsLoading(false));
    if (resp.status === 10) {
      // dispatch(setResultsList(resp.data));
      dispatch(setPersonaList(resp.data));

      if (listOnly === false && bId !== false) {
        const req = _.find(resp.data, { builderRequestId: bId });
        if (req && req.respData) {
          const personaData = _.cloneDeep(req.respData[indexId]);
          const { primaryInterests } = personaData;
          if (!_.isArray(primaryInterests)) {
            // convert the old structure to the new one
            personaData.primaryInterests = _.keys(primaryInterests);
          }
          dispatch(updatePersonaDetails(personaData));
          dispatch(setPersonaDetailsIsLoading(false));
        }
      }
    }
    dispatch(setPersonaListLoading(false));

  };

  const params = listOnly ? { listOnly } : { bId, indexId };

  arClient.doApiCall('/persona/fetch', params, callback);

  dispatch(setPersonaDetailsIsLoading(true));
  dispatch(setPersonaListLoading(true));
};

export const setSelectedCamoaigns = selectedCampaign => async dispatch => {
  dispatch(setPersonaCreationSelectedCampaigns(selectedCampaign));
};

export const cancelGetSuggestionCall = () => async dispatch => {
  dispatch(setPersonaCreationSuggestedInterestsCanceled(true));
  dispatch(setPersonaCreationSuggestedInterests([]));
  dispatch(setPersonaHashtagKeywordsCanceled(true));
  dispatch(setPersonaHashtagKeywords([]));
  dispatch(setPersonaSEOKeywordsCanceled(true));
  dispatch(setPersonaSEOKeywords([]));
};

export const getSuggestedInterests = (interestList, focusedKeyword) => async (
  dispatch,
  getState
) => {
  // dispatch(setPersonaCreationSuggestedInterests(dummyDataInterestsSuggested));
  const {
    Personas: { personaCreationSuggestedInterestsCanceled },
  } = getState();

  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (personaCreationSuggestedInterestsCanceled) {
        dispatch(setPersonaCreationSuggestedInterestsCanceled(false));
        dispatch(setPersonaCreationSuggestedInterests([]));
        resolve(true);
        return;
      } else {
        resolve(true);
        if (resp.status === 10 && resp.data.filtered) {
          dispatch(setPersonaCreationSuggestedInterests(resp.data.filtered));
        }
      }
    };

    arClient.doApiCall(
      '/persona/getSimilarInterests',
      {
        interestList: JSON.stringify(interestList),
        focusedKeyword,
      },
      callback
    );
  });
};


export const getHashtagKeywords = (interestList, focusedKeyword) => async (
  dispatch,
  getState
) => {

  const {
    Personas: { personaHashtagKeywordsCanceled },
  } = getState();

  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (personaHashtagKeywordsCanceled) {
        dispatch(setPersonaHashtagKeywordsCanceled(false));
        dispatch(setPersonaHashtagKeywords([]));
        resolve(true);
        return;
      } else {
        resolve(true);
        if (resp.status === 10 && resp.data) {
          dispatch(setPersonaHashtagKeywords(resp.data));
        }
      }
    };

    arClient.doApiCall(
      '/persona/getHashtagKeywords',
      {
        interestKeyword: JSON.stringify(interestList),
        focusedKeyword,
      },
      callback
    );
  });
};

export const getSEOKeywords = (interestList, focusedKeyword) => async (
  dispatch,
  getState
) => {

  const {
    Personas: { personaSEOKeywordsCanceled },
  } = getState();

  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (personaSEOKeywordsCanceled) {
        dispatch(setPersonaSEOKeywordsCanceled(false));
        dispatch(setPersonaSEOKeywords([]));
        resolve(true);
        return;
      } else {
        resolve(true);
        if (resp.status === 10 && resp.data) {
          dispatch(setPersonaSEOKeywords(resp.data));
        }
      }
    };

    arClient.doApiCall(
      '/persona/getSeoKeywords',
      {
        interestKeyword: JSON.stringify(interestList),
        focusedKeyword,
      },
      callback
    );
  });
};


export const resetsGPTAnswers = () => async dispatch =>
  dispatch(resetChatGPTAnswers());

export const getGPTAnswers = (focusedKeyword, quesNo, context = '') => {
  // dispatch(setPersonaCreationSuggestedInterests(dummyDataInterestsSuggested));

  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10 && resp.data?.data) {
        // dispatch(setPersonaCreationSuggestedInterests(resp.data));
        resolve(resp.data);
      }

      reject(resp);
    };

    arClient.doApiCall(
      '/persona/getAnswers',
      {
        focusedKeyword,
        quesNo,
        context,
      },
      callback
    );
  });
};

export const setSelectedInterests = (
  interestList,
  focusedKeyword,
  campaignId
) => async dispatch => {
  const callback = resp => {
    if (resp.status !== 10) {
      dispatch(setPersonaCreateErrorMsg(resp.error));
    } else {
      dispatch(updatePersonaCreateSuccess(resp.status === 10));
      dispatch(setPersonaCreateBuilderRequestDataId(resp.builderRequestDataId));
    }
  };

  arClient.doApiCall(
    '/persona/addUpdate',
    {
      campaignId,
      status: 'Active',
      interestList: JSON.stringify(interestList),
      focusedKeyword,
    },
    callback
  );
};

export const setFiltersActiveNetwork = activeNetwork => async dispatch => {
  dispatch(setPersonaCreationFiltersActiveNetwork(activeNetwork));
};

export const resetSelectedNetworkFilters = network => async dispatch => {
  dispatch(resetFiltersForNetwork(network));
};

export const setNetworkDefaultFilters = (network,
  filter,
  valueObj,
  validation,
  noMaxLimit) => async (dispatch, getState) => {
    const { from, to } = valueObj || {};

    let value = {};
    let data = {};

    if (filter === 'subscribers_count') {
      value = noMaxLimit ? { from } : { from, to };
      data = {
        network,
        filter,
        value,
        noMaxLimit,
      };
    } else if (filter === 'influencer_followers') {
      data = {
        network,
        filter,
        value: valueObj,
        noMaxLimit,
      };
    } else {
      value = valueObj;
      data = {
        network,
        filter,
        value,
        noMaxLimit: getState().Personas.personaCreationFilters[network]
          .noMaxLimit,
      };
    }

    dispatch(setFilterValue(data));

    // Handling data validation
    if (validation !== undefined) {
      const validationArr = getState().Personas.personaCreationFilters[network]
        .invalidFields;

      const newValidationArr = validation
        ? validationArr.filter(item => item !== filter)
        : validationArr.includes(filter)
          ? validationArr
          : [...validationArr, filter];

      dispatch(
        setFilterSysVal({
          network,
          filter: 'invalidFields',
          value: newValidationArr,
        })
      );
    }

  }

export const setNetworkFilterValue = (
  network,
  filter,
  valueObj,
  validation,
  noMaxLimit
) => async (dispatch, getState) => {
  const { from, to } = valueObj || {};

  let value = {};
  let data = {};

  const currentFilters = getState().Personas.personaCreationFilters;

  if (network === 'instagram') {
    if (!currentFilters['tiktok'].selectedForSubmit) {
      dispatch(setNetworkDefaultFilters('tiktok',
        filter,
        valueObj,
        validation,
        noMaxLimit));
    }
    if (!currentFilters['youtube'].selectedForSubmit) {
      dispatch(setNetworkDefaultFilters('youtube',
        filter,
        valueObj,
        validation,
        noMaxLimit));
    }
  }

  if (filter === 'subscribers_count') {
    value = noMaxLimit ? { from } : { from, to };
    data = {
      network,
      filter,
      value,
      noMaxLimit,
    };
  } else if (filter === 'influencer_followers') {
    data = {
      network,
      filter,
      value: valueObj,
      noMaxLimit,
    };
  } else {
    value = valueObj;
    data = {
      network,
      filter,
      value,
      noMaxLimit: getState().Personas.personaCreationFilters[network]
        .noMaxLimit,
    };
  }

  dispatch(setFilterValue(data));

  // Handling of "Untouched" state and autosaving.
  if (
    data.filter !== 'selectedForSubmit' &&
    data.filter !== 'isUntouched' &&
    data.filter !== 'isSaving'
  ) {
    dispatch(setFilterSysVal({ network, filter: 'isUntouched', value: false }));

    dispatch(
      setFilterSysVal({ network, filter: 'selectedForSubmit', value: true })
    );
  }

  if (data.filter === 'selectedForSubmit') {
    dispatch(
      setFilterSysVal({ network, filter: 'selectedForSubmit', value: true })
    );
  }

  // Handling data validation
  if (validation !== undefined) {
    const validationArr = getState().Personas.personaCreationFilters[network]
      .invalidFields;

    const newValidationArr = validation
      ? validationArr.filter(item => item !== filter)
      : validationArr.includes(filter)
        ? validationArr
        : [...validationArr, filter];

    dispatch(
      setFilterSysVal({
        network,
        filter: 'invalidFields',
        value: newValidationArr,
      })
    );
  }
};

export const resetResultPersonas = () => async dispatch => {
  dispatch(resetResultsList());
};

export const getResultList = () => async dispatch => {
  const callback = resp => {
    console.log(resp);
    if (resp.status === 10) {
      dispatch(setResultsList(resp.data));
    }
    dispatch(setResultsListLoading(false));
  };

  dispatch(setResultsListLoading(true));
  arClient.doApiCall('/persona/fetchAll', {}, callback);
};

export const updatePersonaCreateErrorMsg = msg => async dispatch => {
  dispatch(setPersonaCreateErrorMsg(msg));
};

export const updatePersonaCreateSuccess = status => async dispatch => {
  dispatch(setPersonaCreateSuccess(status));
};
export const resetAllPersonaDetails = () => async dispatch => {
  dispatch(resetPersonaDetails());
};

export const fetchPersonaDetailsByIds = (requestId, personaId) => async (
  dispatch,
  getState
) => {
  const {
    Personas: { personaList },
  } = getState();
  const req = _.find(personaList, { builderRequestId: requestId });
  if (req && req.respData) {
    const personaData = req.respData[personaId];
    dispatch(updatePersonaDetails(personaData));
    dispatch(setPersonaDetailsIsLoading(false));
  }

  dispatch(setPersonaDetailsIsLoading(false));
};

export const fetchPersonaDetailsById = personaId => async dispatch => {
  const callback = resp => {
    console.log('Audience Details Resp: ', resp);
    dispatch(setPersonaDetailsIsLoading(false));
    if (resp.status === 10) {
      const personaData = resp.data[0];

      dispatch(updatePersonaDetails(personaData));

      // dispatch(setPersonaDetailsSummary(personaSummaryDummyData));
    } else {
      console.log(resp);
    }
  };

  dispatch(setPersonaDetailsIsLoading(true));
  arClient.doApiCall(
    '/persona/fetchPersonaDetailsById',
    { personaId },
    callback
  );
};

export const getPostCommentsByIds = (
  rid,
  pid,
  primaryInterest,
  secondaryInterest
) => async dispatch => {
  const callback = resp => {
    dispatch(setPostContentIsLoading(false));
    if (resp.status === 10) {
      dispatch(setPostContentByIds({ [secondaryInterest]: resp.data }));
      // console.log('resp:', resp);
    } else {
      dispatch(setPostContentByIds({}));
    }
  };
  dispatch(setPostContentIsLoading(true));
  arClient.doApiCall(
    '/persona/fetchPostsCommentsById',
    { builderRequestId: rid, indexId: pid, primaryInterest, secondaryInterest },
    callback
  );
};

export const getCreatorsByIds = (rid, pid) => async dispatch => {
  const callback = resp => {
    console.log(resp);

    if (resp.status === 10) {
      if (resp.data.length > 0) {
        const formattedList = resp.data.reduce((resultArr, currVal) => {
          return Array.isArray(currVal) ? resultArr.concat(
            currVal.map(formatCreatorDataListingV3)
          ) : resultArr
        }, [])
        dispatch(setInfluencerListContent(formattedList));
        if (resp.indStdER.length > 0) {
          dispatch(setInfluencerER(resp.indStdER));
        }
      } else {
        dispatch(setInfluencerListContent([]));
      }
    } else {
      console.log('Error loading influencer list');
      console.log(resp);
    }
    dispatch(setInfluencerLoadingState(false));
    dispatch(setInfluencerERLoadingState(false));
  };
  dispatch(setInfluencerLoadingState(true));
  dispatch(setInfluencerERLoadingState(true));
  arClient.doApiCall(
    '/creator/fetchAllV3',
    { builderRequestId: rid, indexId: pid },
    callback
  );
};


/**
 * @param {boolean} list return a list of demographics when it is true, otherwise return latest record                   
 * @returns 
 */
export const fetchDemographicData = (
  builderRequestId,
  segmentId,
  list = false
) => {

  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10) {
        resolve(resp.data);
      } else {
        resolve({});
      }
    }

    arClient.doApiCall(
      '/creator/fetchDemographics',
      { builderRequestId, segmentId, list: list ? 1 : 0 },
      callback
    );
  })
}

export const addInfluencerManually = (
  builderRequestId,
  segmentId,
  infURLArray
) => async dispatch => {
  const callback = resp => {
    if (resp.status === 10) {
      dispatch(getCreatorsByIds(builderRequestId, segmentId));
    } else {
      dispatch(setPersonaCreateErrorMsg('Error adding influencer manually'));
    }
  };

  arClient.doApiCall(
    '/creator/addInfByURLs',
    {
      builderRequestId,
      segmentId,
      infURLArray: JSON.stringify(infURLArray),
    },
    callback
  );
};

export const getCreatorDetailsById = rid => async dispatch => {
  const callback = resp => {
    if (resp.status === 10) {
      resp.data.indStdER = resp.indStdER
      dispatch(setInfluencerDetailsContent(resp.data));
      dispatch(setInfluencerDetailsLoadingState(false));
      if (resp.indStdER) {
        dispatch(setInfluencerER(resp.indStdER));
        dispatch(setInfluencerERLoadingState(false));
      }
    } else {
      console.log('Error loading influencer details');
      console.log('creator-details:', resp);
    }
  };
  dispatch(setInfluencerDetailsLoadingState(true));
  dispatch(setInfluencerERLoadingState(true));
  arClient.doApiCall(
    '/creator/getReportByIdV2',
    { creatorReportDetailsId: rid },
    callback
  );
};

export const getCreatorsBrandSafetyByRequestID = (builderRequestId, creatorReportDetailsId) => async dispatch => {
  const callback = resp => {
    if (resp.status === 10) {
      //console.log("resp++", JSON.parse(resp.data.respData));
      if (!_.isEmpty(resp.data)) {
        dispatch(setInfluencersBrandSafteyData(resp.data));
      } else {
        console.log('Error loading influencer details');
        console.log('no-brand-saftey-data:', resp);
      }
    } else {
      console.log('Error loading influencer details');
      console.log('brand-saftey-details:', resp);
    }
  };


  arClient.doApiCall(
    '/persona/getBrandSafetyV2',
    { personaBuilderRequestId: builderRequestId, creatorReportDetailsId },
    callback
  );
};

export const getCreatorNotesByRId = rid => async dispatch => {
  const callback = resp => {
    if (resp.status === 10) {
      dispatch(setInfluencerNotesContent(JSON.parse(resp.data.all.notes)));
      dispatch(setInfluencerNotesLoadingState(false));
    } else {
      console.log('Error loading influencer details');
      console.log('error:', resp);
    }
  };
  dispatch(setInfluencerNotesLoadingState(true));
  arClient.doApiCall(
    '/creator/getReportById',
    { creatorReportDetailsId: rid },
    callback
  );
};

export const getCreatorNotesByRId2 = rid => async dispatch => {
  const callback = resp => {
    if (resp.status === 10) {
      dispatch(setInfluencerNotesContent(JSON.parse(resp.data[0].notes)));
      dispatch(setInfluencerNotesLoadingState(false));
    } else {
      dispatch(setInfluencerNotesLoadingState(false));
      console.log('Error loading creator notes');
      console.log('error:', resp);
    }
  };
  dispatch(setInfluencerNotesContent([]));
  dispatch(setInfluencerNotesLoadingState(true));
  arClient.doApiCall(
    '/creator/getNotes',
    { creatorReportsDetailId: rid },
    callback
  );
};

export const updateCreatorNotesByRId = (crid, notes) => async dispatch => {
  const callback = resp => {
    if (resp.status === 10) {
      dispatch(setInfluencerNotesContent(notes));
      dispatch(setInfluencerNotesLoadingState(false));
    } else {
      dispatch(setInfluencerNotesLoadingState(false));
      console.log('Error updating influencer details');
      console.log('error:', resp);
    }
  };

  dispatch(setInfluencerNotesLoadingState(true));

  const notesArr = JSON.stringify(notes);

  arClient.doApiCall(
    '/creator/addnotes',
    {
      creatorReportsDetailId: crid,
      note: notesArr,
    },
    callback
  );
};

export const updateCreatorDetailsInsight = (
  rid,
  insights
) => async dispatch => {
  const callback = resp => {
    if (resp.status === 10) {
      dispatch(setInfluencerDetailsInsight(insights));
      dispatch(setInfluencerDetailsLoadingState(false));
    } else {
      console.log('Error updating influencer details');
      console.log('error:', resp);
      dispatch(setInfluencerDetailsLoadingState(false));
    }
  };
  dispatch(setInfluencerDetailsLoadingState(true));
  arClient.doApiCall(
    '/creator/addUpdateInsights',
    { creatorReportsDetailId: rid, insights },
    callback
  );
};

export const updateCreatorDetailsProsnCons = (
  rid,
  prosCons
) => async dispatch => {
  const callback = resp => {
    if (resp.status === 10) {
      dispatch(setInfluencerDetailsProsCons(prosConsObj));
      dispatch(setInfluencerDetailsLoadingState(false));
    } else {
      dispatch(setInfluencerDetailsLoadingState(false));
    }
  };
  dispatch(setInfluencerDetailsLoadingState(true));

  const prosConsObj = JSON.stringify(prosCons);

  arClient.doApiCall(
    '/creator/addUpdateProsCons',
    { creatorReportsDetailId: rid, prosCons: prosConsObj },
    callback
  );
};

export const updateCreatorBudget = (
  rid,
  budget
) => async dispatch => {
  const callback = resp => {
    if (resp.status === 10) {
      dispatch(setInfluencerDetailsLoadingState(false));
    } else {
      dispatch(setInfluencerDetailsLoadingState(false));
    }
  };
  dispatch(setInfluencerDetailsLoadingState(true));

  arClient.doApiCall(
    '/creator/addUpdateBudget',
    { creatorReportsDetailId: rid, budget: budget },
    callback
  );
};

export const updatePersonaStatus = (rid, pid, status) => async dispatch => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      dispatch(setPersonaStatusUpdateLoading(false));
      if (resp.status === 10) {
        resolve(resp);
      } else {
        reject(resp);
      }
    };

    dispatch(setPersonaStatusUpdateLoading(true));

    arClient.doApiCall(
      '/persona/updateStatusById',
      { builderRequestId: rid, indexId: pid, status },
      callback
    );
  });
};

export const updateRejectInf = rejectObj => async dispatch => {
  return new Promise((resolve, reject) => {
    console.log('rejectObj', rejectObj);

    const callback = resp => {
      dispatch(setPersonaStatusUpdateLoading(false));
      if (resp.status === 10) {
        resolve(resp);
      } else {
        reject(resp);
      }
    };

    dispatch(setPersonaStatusUpdateLoading(true));

    arClient.doApiCall(
      '/creator/reject',
      { rejectObj: JSON.stringify(rejectObj) },
      callback
    );
  });
};

export const updateInfluStatus = (
  builderRequestId,
  segmentId,
  rejectObj
) => async dispatch => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      dispatch(setInfluencerLoadingState(false));
      if (resp.status === 10) {
        resolve(resp);
      } else {
        reject(resp);
      }
    };

    dispatch(setInfluencerLoadingState(true));
    /* console.log('======================================================');
    console.log('rejectObj', rejectObj);
    console.log('payload', {
      builderRequestId: requestId,
      indexId: personaId,
      statusObj: rejectObj,
    });*/

    arClient.doApiCall(
      '/creator/updateStatus',
      {
        builderRequestId,
        segmentId,
        statusObj: JSON.stringify(rejectObj),
      },
      callback
    );
  });
};

export const updatePersonaName = (rid, pid, name) => async dispatch => {
  const callback = resp => {
    console.log(resp);
  };

  arClient.doApiCall(
    '/persona/updateNameById',
    { builderRequestId: rid, indexId: pid, name },
    callback
  );
};

export const updateCreatorFilter = (rid, respData) => async dispatch => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10) {
        setCreatorFiltersSavedSuccess(true);
        resolve(true);
      } else {
        setCreatorFiltersSavedErrMsg(
          'API Failed! Something went wrong. Please try again.'
        );
        reject('API Failed! Something went wrong. Please try again.');
      }
    };

    // console.log('respData', JSON.parse(respData));

    arClient.doApiCall(
      '/persona/setCreatorFilters',
      {
        builderRequestId: rid,
        respData,
      },
      callback
    );
  });
};


export const checkPersonaForSavedFilters = rid => async dispatch => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10) {
        if (!_.isEmpty(resp.data)) {
          try {
            dispatch(setFiltersFromSaved(
              reconcileCreatorFiltersData(resp.data)
            ));
          } catch (error) {
            console.log('Failed to set filters');
            console.log(error);
          }
        }

        dispatch(setFiltersLoadingState(false));
        resolve(true);
      } else {
        dispatch(setFiltersLoadingState(false));
        reject('API Failed! Something went wrong. Please try again.');
      }
    };

    dispatch(setFiltersLoadingState(true));

    arClient.doApiCall(
      '/persona/getCreatorFilters',
      {
        builderRequestId: rid,
      },
      callback
    );
  });
};

export const runInfService = (requestId, serviceName) => async dispatch => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10) {
        resolve(resp);
      } else {
        reject(resp);
      }
    };

    arClient.doApiCall(
      '/creator/runService',
      {
        requestId,
        serviceName,
      },
      callback
    );
  });
};

export const fetchPersonaSegmentByIds = (
  requestId,
  segmentId
) => async dispatch => {
  return new Promise((resolve, reject) => {
    const callback = resp => {

      if (ENV !== 'PRODUCTION') console.log(resp);

      if (resp.status === 10) {
        // return only the segment data
        const segmentData = resp.data[0]?.respData[segmentId];
        if (segmentData) {
          const { primaryInterests } = segmentData;
          if (!_.isArray(primaryInterests)) {
            segmentData.primaryInterests = _.keys(primaryInterests);
          }
        }
        resolve(segmentData);
      } else {
        reject(resp);
      }
    };

    arClient.doApiCall(
      '/persona/fetch',
      { bId: requestId, indexId: segmentId },
      callback
    );
  });
};

export const updateIsCompareActive = (status, cmpId) => async dispatch => {
  dispatch(setIsCompareActive(status));
  dispatch(setComparePersonaCampaign(cmpId));
};

export const updateSelectedPersonaForCompArr = personaIdArr => async dispatch => {
  dispatch(setSelectedPersonaForCompArr(personaIdArr));
};

export function fetchPersonasForComparison(personIdArr) {
  function fetchPersonaDetails(params) {
    return new Promise((resolve, reject) => {
      const callback = resp => {
        if (resp.status === 10 && resp.data) {
          resolve(resp.data);
        }
        reject(resp);
      };
      arClient.doApiCall('/persona/fetch', params, callback);
    });
  }

  return Promise.all(
    personIdArr.map(({ requestId, segmentId }) =>
      fetchPersonaDetails({ bId: requestId, indexId: segmentId })
    )
  ).then(data => {
    return data.map((item, idx) => {
      const { requestId, segmentId } = personIdArr[idx];
      const personaData = item[0].respData[segmentId];
      const { primaryInterests } = personaData;
      if (!_.isArray(primaryInterests)) {
        // convert the old structure to the new one
        personaData.primaryInterests = _.keys(primaryInterests);
      }

      if (personaData.sourcesV2) {
        const { sourcesReddit, sourcesTwitter } = personaData.sourcesV2;
        personaData.sourceData = {
          sourcesReddit: {
            ...sourcesReddit,
            totalPostCommentsCount: getTotalPostCommentsCount(sourcesReddit),
          },
          sourcesTwitter: {
            ...sourcesTwitter,
            totalPostCommentsCount: getTotalPostCommentsCount(sourcesTwitter),
          },
        }
      }
      else {
        personaData.sourceData = {
          sourcesReddit: {
            ...personaData.sources,
            totalPostCommentsCount: getTotalPostCommentsCount(data.sources),
          },
          sourcesTwitter: {
            followers: 0,
            meta: [],
            totalPostCommentsCount: 0,
          },
        }
      }

      personaData.demographics = reconcileDemographicsData(personaData);
      return {
        ...personaData,
        requestId,
        personaId: segmentId,
      };
    });
  });
}

export const getContentInfluencer = (creatorReportDetailsId) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const callback = (resp) => {

      if (resp?.status === 10 || resp?.status === 'INIT') {
        if (resp.creatorDetailsData) {
          const formattedData = formatContentData(resp)
          dispatch(setInfluencerContent(formattedData));
        } else {
          dispatch(setInfluencerContent([]));
        }
        resolve(resp);
      } else {
        dispatch(setInfluencerContent([]));
        console.log('Error loading influencer content');
        console.log(resp);
        reject(resp);
      }


      dispatch(setInfluencerContentLoadingState(false));
    };

    dispatch(setInfluencerContentLoadingState(true));

    arClient.doApiCall('/creator/getContent', { creatorReportDetailsId }, callback, {

    });
  });
};

export const getMatchingContentInfluencer = (creatorReportDetailsId, personaBuilderRequestId, segmentId) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const callback = (resp) => {

      if (resp?.status === 10) {
        if (resp.creatorDetailsData) {
          const formattedData = formatContentData(resp)
          dispatch(setInfluencerMatchingContent(formattedData));
        } else {
          dispatch(setInfluencerMatchingContent([]));
        }
        resolve(resp);
      } else {
        dispatch(setInfluencerMatchingContent([]));
        console.log('Error loading influencer matching content');
        console.log(resp);
        reject(resp);
      }


      dispatch(setInfluencerMatchingContentLoading(false));
    };

    dispatch(setInfluencerMatchingContentLoading(true));


    arClient.doApiCall('/persona/post-recommend-details', { personaBuilderRequestId, creatorReportDetailsId, segmentId }, callback, {

    });
  });
};


export const getRevisionsDataInfluencer = (creatorReportDetailsId) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const callback = (resp) => {
      dispatch(setIsInfluencerRevisionsFoundLoading(false));

      if (resp.status === 10) {
        if (!_.isEmpty(resp.data)) {
          console.log('yeah');
          console.log(resp.data.contentRevisionDetails[0]?.contentRevisionId);
          dispatch(setIsInfluencerRevisionsFound(resp.data.contentRevisionDetails[0]?.contentRevisionId));
          dispatch(
            setContentAgreementData({
              id: resp.data.id,
              agreementList: JSON.parse(resp.data.contentJson),
            })
          )
        }
        else {
          dispatch(setIsInfluencerRevisionsFound(false));
        }
        resolve(resp);
      } else {
        console.log('Error API Failed')
        reject(resp);
      }
    };

    dispatch(setIsInfluencerRevisionsFoundLoading(true));
    arClient.doApiCall('/content-revision/createUpdate', { creatorReportDetailsId }, callback, {

    });
  });
};

export const getRevisionsList = (contentRevisionId) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const callback = (resp) => {
      //dispatch(setIsInfluencerRevisionsListLoading(false));

      if (resp.status === 10) {
        if (!_.isEmpty(resp.data)) {
          dispatch(setInfluencerRevisionsList(resp.data));
        }
        else {
          dispatch(setInfluencerRevisionsList([]));
        }
        dispatch(setIsInfluencerRevisionsListLoading(false));
        resolve(resp);
      } else {
        dispatch(setInfluencerRevisionsList([]));
        dispatch(setIsInfluencerRevisionsListLoading(false));
        console.log('Error API Failed')
        reject(resp);
      }
    };

    dispatch(setIsInfluencerRevisionsListLoading(true));
    arClient.doApiCall('/content-revision/fetchAll', { contentRevisionId }, callback, {

    });
  });
};

export const getRevisionsConversationClient = (contentRevisionDetailsId) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const callback = (resp) => {
      dispatch(setIsInfluencerRevisionsConversationLoading(false));

      if (resp.status === 10) {
        console.log(resp);
        if (!_.isEmpty(resp.data)) {
          dispatch(setInfluencerRevisionsConversation(resp.data));
        }
        resolve(resp);
      } else {
        console.log('Error API Failed')
        reject(resp);
      }
    };

    dispatch(setIsInfluencerRevisionsConversationLoading(true));
    arClient.doApiCall('/content-revision/getConversation', { contentRevisionDetailsId }, callback, {

    });
  });
};

export const sendMessageRevisionsConversationClient = (msgObj, selectedRevisionId) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const callback = (resp) => {
      if (resp.status === 10) {
        console.log(resp);
        if (!_.isEmpty(resp.data)) {
          dispatch(getRevisionsConversationClient(selectedRevisionId));
        }
        resolve(resp);
      } else {
        console.log('Error API Failed')
        reject(resp);
      }
    };

    dispatch(setIsInfluencerRevisionsConversationLoading(true));
    arClient.doApiCall('/content-revision/addClientResponse', msgObj, callback, {

    });
  });
};

export const sendInstructionsRevisionsConversationClient = (instructions, contentRevisionDetailsId) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const callback = (resp) => {
      if (resp.status === 10) {
        console.log(resp);
        if (!_.isEmpty(resp.data)) {
          dispatch(setRevisionStatusConversationClient('completed', contentRevisionDetailsId));
        }
        resolve(resp);
      } else {
        console.log('Error API Failed')
        reject(resp);
      }
    };

    dispatch(setIsInfluencerRevisionsConversationLoading(true));
    arClient.doApiCall('/content-revision/addDeliveryInstructions', { deliveryInstructions: JSON.stringify(instructions), contentRevisionDetailsId }, callback, {

    });
  });
};

export const setRevisionStatusConversationClient = (status, contentRevisionDetailsId) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const callback = (resp) => {
      if (resp.status === 10) {
        dispatch(getRevisionsConversationClient(contentRevisionDetailsId));
        resolve(resp);
      } else {
        console.log('Error API Failed')
        reject(resp);
      }
    };

    dispatch(setIsInfluencerRevisionsConversationLoading(true));
    arClient.doApiCall('/content-revision/setStatus', { contentRevisionDetailsId, status }, callback, {

    });
  });
};

export const clearRevisionsConversationClient = () => async (dispatch) => {
  dispatch(setInfluencerRevisionsConversation({}));
};

export const getAudiencesList = (campaignId = null) => async dispatch => {
  const callback = respApi => {
    if (respApi.status === 10) {
      dispatch(setAudienceList(respApi.data));
    } else {
      dispatch(setAudienceList([]));
    }
    dispatch(setAudienceListLoadingState(false));
  };

  dispatch(setAudienceListLoadingState(true));

  const params = campaignId === null ? {} : { campaignId };

  arClient.doApiCall('/persona/getPersonaListByCampaignId', params, callback);
};


export function fetchContentAnalysis(creatorReportDetailsId, creatorPostId) {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      try {
        const respData = JSON.parse(resp.data.respData);
        const formattedData = respData['Posts'].map((item) => formatContentAnalysisData(item.analysis, item.meta));
        resolve(formattedData);
      } catch (e) {
        resolve([]);
      }
    }
    arClient.doApiCall('/creator/fetchAnalysis', { creatorReportDetailsId, creatorPostId }, callback);
  })

}


export const getContentRevisionCount = contentRevisionDetailsId => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10) {
        resolve(resp);
      } else {
        reject(resp);
      }
    };

    arClient.doApiCall(
      '/content-revision/getConversationCount',
      {
        contentRevisionDetailsId,
      },
      callback
    );
  });
};

export const getContentRevisionInfCount = contentRevisionDetailsId => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10) {
        resolve(resp);
      } else {
        reject(resp);
      }
    };

    arClient.doApiCall(
      '/custom/get-conversation-count',
      {
        contentRevisionDetailsId,
      },
      callback
    );
  });
};

export const postTikTokAdAccountId = (advertiserId, authCode) => {
  return new Promise((resolve, reject) => {
    const callback = resp => {
      if (resp.status === 10) {
        resolve(resp);
      } else {
        reject(resp);
      }
    };

    arClient.doApiCall(
      '/tiktok/check-advertiser',
      {
        advertiserId,
        authCode
      },
      callback
    );
  });
};

export const getPersonaLite = () => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const callback = (resp) => {
      dispatch(setPersonaLiteListLoading(false));

      if (resp.status === 10) {
        console.log(resp);
        if (!_.isEmpty(resp.data)) {
          dispatch(setPersonaLiteList(resp.data))
        }
        resolve(resp);
      } else {
        console.log('Error API Failed')
        reject(resp);
      }
    };

    dispatch(setPersonaLiteListLoading(true));
    arClient.doApiCall('/persona/fetchLite', {}, callback, {

    });
  });
};


export default PersonasSlice.reducer;
